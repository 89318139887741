import React from 'react'
import ReactDOM from 'react-dom'
import promiseMiddleware from 'redux-promise'
import thunkMiddleware from 'redux-thunk'
import { Provider } from 'react-redux'
import { compose, createStore, applyMiddleware } from 'redux'
import rootReducer from 'reducers/index'
import _ from 'underscore'
import MonkeyPatcher from 'Services/MonkeyPatcher'
import { createLoadable } from 'components/loadable'
import { initializeSentry } from 'utils/sentry'

import 'v2/components/Multiverse/Lenders/index'

import 'i18n'

initializeSentry('multiverse')

const PageInfoStub = createLoadable(() => import('components/PageInfoStub'))

const AccountCreationContainer = createLoadable(() => import('v2/containers/AccountCreationContainer'))
const BorrowerProfileContainer = createLoadable(() => import('v2/containers/BorrowerProfileContainer'))
const ConfirmationsContainer = createLoadable(() => import('v2/containers/ConfirmationsContainer'))
const LenderOnboardingContainer = createLoadable(() => import('v2/containers/LenderOnboardingContainer'))
const LenderRouter = createLoadable(() => import('v2/Router/LenderRouter'))
const OrganizationSettingsContainer = createLoadable(
  () => import('v2/containers/Settings/OrganizationSettingsContainer')
)
const TwoFactorAuthContainer = createLoadable(() => import('v2/containers/TwoFactorAuthContainer'))
const UsersSettingsContainer = createLoadable(
  () => import('v2/containers/Settings/UsersSettingsContainer')
)

const OTPVerificationForm = createLoadable(() => import('v2/components/Borrowers/AccountCreation/OTPVerificationForm'))
const ResetPasswordForm = createLoadable(() => import('v2/components/Borrowers/AccountCreation/ResetPasswordForm'))
const SetNewPasswordForm = createLoadable(() => import('v2/components/Borrowers/AccountCreation/SetNewPasswordForm'))
const SignInForm = createLoadable(() => import('v2/components/Borrowers/AccountCreation/SignInForm'))
const SingleSignOnTriageForm = createLoadable(
  () => import('v2/components/Borrowers/AccountCreation/SingleSignOnTriageForm')
)

const InvitationAcceptForm = createLoadable(() => import('v2/components/Global/AccountCreation/InvitationAcceptForm'))

MonkeyPatcher.objectAssign()
MonkeyPatcher.arrayIncludes()

const middlewares = [thunkMiddleware, promiseMiddleware]

const mainApp = () => {
  MAXWELL.react.registerComponent('AccountCreationContainer', AccountCreationContainer)
  MAXWELL.react.registerComponent('BorrowerProfileContainer', BorrowerProfileContainer)
  MAXWELL.react.registerComponent('ConfirmationsContainer', ConfirmationsContainer)
  MAXWELL.react.registerComponent('InvitationAcceptForm', InvitationAcceptForm)
  MAXWELL.react.registerComponent('LenderOnboardingContainer', LenderOnboardingContainer)
  MAXWELL.react.registerComponent('LenderRouter', LenderRouter)
  MAXWELL.react.registerComponent('OrganizationSettingsContainer', OrganizationSettingsContainer)
  MAXWELL.react.registerComponent('OTPVerificationForm', OTPVerificationForm)
  MAXWELL.react.registerComponent('PageInfoStub', PageInfoStub)
  MAXWELL.react.registerComponent('ResetPasswordForm', ResetPasswordForm)
  MAXWELL.react.registerComponent('SetNewPasswordForm', SetNewPasswordForm)
  MAXWELL.react.registerComponent('SignInForm', SignInForm)
  MAXWELL.react.registerComponent('SingleSignOnTriageForm', SingleSignOnTriageForm)
  MAXWELL.react.registerComponent('TwoFactorAuthContainer', TwoFactorAuthContainer)
  MAXWELL.react.registerComponent('UsersSettingsContainer', UsersSettingsContainer)

  // load react components from DOM
  findReactNodes().each((elem) => {
    loadReactComponent(elem)
  })

  $(() => {
    $(document).on('DOMNodeInserted', (e) => {
      $(e.target).find("[data-integration-name='react-component']").each((elem) => {
        loadReactComponent(elem)
      })
    })
  })

  // TODO:: Implement if needed
  // Add default redux components
  // const notificationsElement = $("<div data-integration-name='react-redux-component'></div>")
  // notificationsElement.data('options', { name: 'Notifications' })
  // $(document.body).prepend(notificationsElement)

  // const overlaySpinner = $("<div data-integration-name='react-redux-component'></div>")
  // overlaySpinner.data('options', { name: 'OverlaySpinnerContainer' })
  // $(document.body).prepend(overlaySpinner)

  // load react-redux components from DOM
  const reduxNodes = findReduxNodes()
  const partialStates = _.map(reduxNodes, (elem) => { return $(elem).data('options').initialState })
  const initialState = _.extend.apply(this, [{}, ...partialStates])

  // Create one single shared Redux store
  const composeEnhancers = process.env.NODE_ENV !== 'production'
    && typeof window === 'object'
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose

  const enhancer = composeEnhancers(
    applyMiddleware(...middlewares),
    // other store enhancers if any
  )

  window.store = createStore(
    rootReducer,
    initialState,
    enhancer,
  )

  reduxNodes.each((i, elem) => {
    loadReduxComponent(elem)
  })

  $(() => {
    $(document).on('DOMNodeInserted', (e) => {
      $(e.target).find("[data-integration-name='react-redux-component']").each((i, elem) => {
        loadReduxComponent(elem)
      })
    })
  })

  $(document).trigger('maxwell-react:initialized') // subscribe to this event to identify when app is initialized.
}

function loadReactComponent(elem) {
  const $elem = $(elem)
  const componentName = $elem.data('options').name
  const payload = $elem.data('payload')
  const containerElement = React.createElement(MAXWELL.react.components[componentName], payload)

  ReactDOM.render(
    containerElement,
    elem
  )
}

function loadReduxComponent(elem) {
  const $elem = $(elem)
  const componentName = $elem.data('options').name
  const payload = $elem.data('payload')
  const containerElement = React.createElement(MAXWELL.react.components[componentName], payload)

  ReactDOM.render(
    React.createElement(Provider, { store: window.store }, containerElement),
    elem
  )
}

function findReactNodes() {
  if ($) {
    return $("[data-integration-name='react-component']")
  } else {
    console.error('jQuery is not loaded')
  }
}

function findReduxNodes() {
  if ($) {
    return $("[data-integration-name='react-redux-component']")
  } else {
    console.error('jQuery is not loaded')
  }
}

$(() => mainApp())
