import React, { lazy, Suspense } from 'react'
import { I18nextProvider } from 'react-i18next'
import i18n from 'i18n'

export const createLoadable = dynamicComponent => class LoadableComponent extends React.PureComponent {
  render() {
    const DynamicComponent = lazy(dynamicComponent)
    return (
      <Suspense fallback={null}>
        <I18nextProvider i18n={i18n}>
          <DynamicComponent {...this.props} />
        </I18nextProvider>
      </Suspense>
    )
  }
}
