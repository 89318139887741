/* eslint no-extend-native: "warn" */

import { extend } from 'underscore'

export default {
  objectAssign: () => { Object.assign = Object.assign || extend },
  arrayIncludes: () => {
    if (!Array.prototype.includes) {
      Object.defineProperty(Array.prototype, 'includes', {
        enumerable: false,
        value: function (obj) {
          const newArr = this.filter(el => el === obj);
          return newArr.length > 0;
        },
      });
    }
  },
}
